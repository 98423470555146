import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block--comparison .accordion',
  ),
});

export default defineModule(() => {
  // TODO implement
});
